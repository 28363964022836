<template>
	<menu-component :menuItems="menuItems" />
</template>
<script>
import MenuComponent from './Menu.vue'

export default {
	components: { MenuComponent },
	data() {
		return {
			menuItems: [
				{
					path: `/ac/${this.$route.params.accountId}`,
					title: this.$t('ACCOUNTS'),
					menu: 'main',
				},
			],
		}
	},
}
</script>
