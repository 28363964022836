<template>
	<menu-component :menu-items="menuItems" />
</template>
<script>
import MenuComponent from './Menu.vue'
import {useDocumentsStore} from "~/stores/documents";

export default {
	components: { MenuComponent },
  setup() {
    const documentsStore = useDocumentsStore()
    return {
      documentsStore
    }
  },
	data() {
		return {
			baseItems: [
				{
					path: `/fo/${this.$route.params.accountId}/orders`,
					title: this.$t('forest_owner.ORDERS'),
					menu: 'main',
				},
				{
					path: `/fo/${this.$route.params.accountId}/accounting`,
					title: this.$t('ACCOUNTING'),
					menu: 'main',
				},
				{
					path: `/fo/${this.$route.params.accountId}/contact`,
					title: this.$t('CONTACT'),
					menu: 'main',
				},
			],
		}
	},
	computed: {
		menuItems() {
			if (this.documentsStore.items?.length) {
				// Account has documents - they should get the chance to navigate to them
				return [...this.baseItems, {
					path: `/fo/${this.$route.params.accountId}/documents`,
					title: this.$t('DOCUMENTS'),
					menu: 'main'
				}]
			}
			// no docs, no need to show the documents link
			return this.baseItems
		}
	}
}
</script>
