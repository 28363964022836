<template>
	<header class="banner" :class="{ 'banner--transparent': !isLoggedIn }">
		<div class="constrained">
			<div class="flex">
				<nuxt-link :to="basePath" class="banner__logo">
					<img
						v-if="isFetching"
						src="/img/gms-myprofile-blue-01.svg"
						width="48"
						height="48"
						class="mr-2"
					/>
					<avatar
						v-else-if="accountName"
						:username="accountName"
						:size="48"
						class="mr-2 flex-shrink-0"
					/>
					<div class="text-grey-darker">
						<div class="text-xs leading-tight">{{ $t('ACCOUNTANT') }}</div>
						<div class="text-xl leading-none">
							{{ isFetching ? $t('LOADING') : accountName }}
						</div>
					</div>
				</nuxt-link>
				<menu-accountant />
			</div>
		</div>
	</header>
</template>
<script>
import {GMAvatar as Avatar} from '@gm/components'
import {mapState} from "pinia";
import MenuAccountant from '../MenuAccountant.vue'
import {useAccountStore} from "~/stores/account";
import {useUserStore} from "~/stores/user";
import {useAccountService} from "~/services/account";

export default {
	components: {
		MenuAccountant,
		Avatar,
	},
  setup() {
    const { getBasePath, getAccountName, isFetchingActiveAccount, accountsQuery} = useAccountService();
    const { data: accounts, isFetching: isFetchingAccounts } = accountsQuery()
    const accountName = computed(() => getAccountName(accounts))
    const basePath = computed(() => getBasePath(accounts))

    const isFetching = computed(() => isFetchingAccounts.value || isFetchingActiveAccount.value)
    return {
      basePath,
      isFetching,
      accountName,
    }
  },
	computed: {
		...mapState(useUserStore, ['isLoggedIn']),
	},
}
</script>
