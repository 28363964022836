<template>
	<GMModal v-if="isShowing" v-bind:show="showDelay" @close="close">
    <template #header>
      <div class="flex flex--align-center">
        {{ $t('PRIVACY_INFORMATION') }}
      </div>
    </template>
    <template #default>
      <div class="max-w-sm">
        <div v-if="isFetching">
          <div class="loader h-space--right"></div>
          <span>{{ $t('LOADING') }}</span>
        </div>
        <div class="align--left" v-html="text"></div>
      </div>
    </template>
		<template #footer>
			<button class="btn--link btn--block" @click.stop.prevent="close">
				{{ $t('CANCEL') }}
			</button>
		</template>
	</GMModal>
</template>
<script lang="ts">
import {storeToRefs} from "pinia";

import {GMModal} from "@gm/components";
import {usePrivacyInformationStore} from '~/stores/privacyInformation';

export default defineComponent({
	components: {
		GMModal,
	},
	setup() {
		const showDelay = ref(false)
		const store = usePrivacyInformationStore()
		const {
			isShowing,
			text,
			isFetching,
			errorMessage,
		} = storeToRefs(store)

		const close = () => {
			showDelay.value = false
			setTimeout(() => {
				store.hide()
			}, 300)
		}

		watch(isShowing, (show) => {
			if (show) {
				store.fetch()

				setTimeout(() => {
					showDelay.value = true
				}, 300)
			}
		})

		return {
			showDelay,
			isShowing,
			text,
			isFetching,
			errorMessage,
			close,
		}
	},
})
</script>
