<template>
	<footer class="constrained" role="contentinfo">
		<div class="footer">
			<ul class="inline-list inline-list--center">
				<li>&copy; 2012 &ndash; {{ new Date().getFullYear() }}</li>
				<li>
					<a
						class="novisited font-light"
						:class="{
							'text-white': !isLoggedIn || isLoggingIn,
							'text-blue-darker': isLoggedIn && false,
						}"
						href="https://www.glommen-mjosen.no/"
					>
						Glommen Mjøsen Skog SA
					</a>
				</li>
				<li>
					<show-privacy-information-button
						class="p-0 font-light"
						:class="{
							'text-white': !isLoggedIn || isLoggingIn,
							'text-blue-darker': isLoggedIn,
						}"
					/>
				</li>

				<li>Skogeierportal {{ $config.public.version }}</li>
			</ul>
		</div>
	</footer>
</template>
<script>
import ShowPrivacyInformationButton from '@/components/buttons/ShowPrivacyInformationButton.vue'
import {mapState} from "pinia";
import {useUserStore} from "~/stores/user";

export default {
	components: {
		ShowPrivacyInformationButton,
	},
	computed: {
		...mapState(useUserStore, ['isLoggedIn', 'isLoggingIn']),
	},
}
</script>
