<template>
	<nav ref="container" class="banner__menu" role="navigation">
		<ul class="menu" :class="{ 'menu--hidden': isRecalculating }">
			<li v-for="item in mainMenuItems" ref="menuItems">
				<nuxt-link class="menu__item" :to="item.path" :title="item.path" :tag="tag" :class="{
          /* HACK: nuxt injecting router linkActiveClass does not work in this context, manually apply it */
          'menu__item--active': pathContains($route.path, item.path)
				}">{{
					item.title
				}}</nuxt-link>
			</li>
			<li v-show="subMenuItems.length || showExtraMenuItems">
				<button
					ref="menuBtn"
					class="menu__item__btn menu__item btn-icon--only"
					:class="{
						'menu__item--active': activeItemIsInSubMenu,
						'menu__item__btn--expanded': showSubmenu,
					}"
					:aria-expanded="showSubmenu"
					:aria-controls="`menu_${_uid}_submenu`"
					:disabled="!isLoggedIn"
					@click="showSubmenu = !showSubmenu"
				>
					<svg
						height="16"
						viewBox="0 0 18 16"
						width="18"
						xmlns="http://www.w3.org/2000/svg"
					>
						<g
							fill="none"
							fill-rule="evenodd"
							stroke="#7a7b7e"
							stroke-linecap="round"
							stroke-linejoin="round"
							transform=""
						>
							<path class="line1" d="m17.5 3.5h-17v-3h17z" fill="#c7e7d3" />
							<path class="line2" d="m17.5 9.5h-17v-3h17z" fill="#afdfc1" />
							<path class="line3" d="m17.5 15.5h-17v-3h17z" fill="#98d8b1" />
							<path
								class="close"
								d="m9 5.87867966 4.9497475-4.94974747 2.1213203 2.12132034-4.9497475 4.94974747 4.9497475 4.9497475-2.1213203 2.1213203-4.9497475-4.9497475-4.94974747 4.9497475-2.12132034-2.1213203 4.94974747-4.9497475-4.94974747-4.94974747 2.12132034-2.12132034z"
								fill="#c7e7d3"
							/>
						</g></svg
					><span class="visuallyhidden">{{ menuText }}</span>
					<div class="menu__item__expander"></div>
				</button>
			</li>
		</ul>
		<transition name="transition--fade">
			<ul
				v-if="
					isLoggedIn &&
					(subMenuItems.length || showExtraMenuItems) &&
					showSubmenu
				"
				:id="`menu_${_uid}_submenu`"
				class="submenu"
				:class="{ 'menu--hidden': isRecalculating }"
			>
				<li v-for="(item, index) in subMenuItems">
					<nuxt-link
						class="submenu__item"
						:to="item.path"
						:tag="tag"
						active-class="submenu__item--active"
						exact-active-class=""
						@click.native="showSubmenu = false"
						@keydown.native.tab.exact="
							!showExtraMenuItems && subMenuTab(index, false, $event)
						"
						>{{ item.title }}</nuxt-link
					>
				</li>
				<li v-if="showExtraMenuItems">
					<div class="bg-blue-lightest p-2">
						{{ $t('LOGGED_IN_AS_NAME', { name: loggedInUserName }) }}
					</div>
				</li>
				<li v-if="showExtraMenuItems">
					<nuxt-link
						class="submenu__item"
						to="/my-account"
						active-class="submenu__item--active"
						exact-active-class=""
						@click.native="showSubmenu = false"
						><i class="icon icon--dark-gray h-space--right--small">
							<svg-icon width="16" height="16" name="settings" /></i
						><span>{{ $t('MY_ACCOUNT') }}</span></nuxt-link
					>
				</li>
				<li v-if="showExtraMenuItems">
					<button
						class="submenu__item btn--link"
						@click="logout"
						@keydown.tab.exact="
							!!showExtraMenuItems && subMenuTab(null, true, $event)
						"
					>
						<i class="icon icon--dark-gray h-space--right--small">
							<svg-icon width="16" height="16" name="lock-closed" /></i
						><span>{{ $t('LOG_OUT') }}</span>
					</button>
				</li>
			</ul>
		</transition>
	</nav>
</template>
<script>
import {mapState, mapStores} from "pinia";
import {useUserStore} from "~/stores/user";
import debounce from "lodash/debounce";
import {pathContains} from "~/helpers/pathContains";

export default {
	props: {
		menuItems: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			availableWidth: 0,
			isRecalculating: false,
			lastCalculatedWidth: 0,
			showSubmenu: false,
			windowWidth: window.innerWidth,
		}
	},
	setup() {
		const userStore = useUserStore()
		return {
			userStore,
		}
	},
	computed: {
		...mapState(useUserStore, {
			loggedInUserName: (state) => state.name,
			isLoggedIn: (state) => state.isLoggedIn,
		}),
		activeItemIsInSubMenu() {
			const routePath = this.$route.path
			const activeMenuItem = this.menuItems.find(
				(item) => item.path === routePath
			)

			if (activeMenuItem) {
				return activeMenuItem.menu === 'sub'
			} else if (routePath === '/my-account') {
				return true
			}

			return false
		},
		mainMenuItems() {
			return this.menuItems.filter((item) => item.menu === 'main')
		},
		menuText() {
			return this.showSubmenu ? this.$t('HIDE_MENU') : this.$t('MENU')
		},
		showExtraMenuItems() {
			const windowWidth = this.windowWidth
			return windowWidth < 650
		},
		subMenuItems() {
			return this.menuItems.filter((item) => item.menu === 'sub')
		},
		tag() {
			return this.isLoggedIn ? 'a' : 'span'
		},
	},
	methods: {
    pathContains,
		getAvailableWidth: debounce(function () {
			const windowWidth = window.innerWidth

			if (this.lastCalculatedWidth == windowWidth) {
				return
			}

			if (windowWidth < 401) {
				this.menuItems.forEach((item, index) => {
					this.menuItems[index].menu = 'sub'
				})
			} else if (this.$refs.hasOwnProperty('container')) {
					const aWidths = []
					this.isRecalculating = true
					this.windowWidth = windowWidth

					// Move all menu items to main menu so the widths can be measured
					this.menuItems.forEach((item, index) => {
						this.menuItems[index].menu = 'main'
					})

					// Wait 50ms for the elements to be rendred (this value might need adjustment)
					setTimeout(() => {
						// Measure each element in the main menu
						this.$refs.menuItems &&
							this.$refs.menuItems.forEach((item) => {
								aWidths.push({
									item,
									width: item.offsetWidth,
								})
							})

						// Move all menu items to 'submenu' to get available space for main menu
						this.menuItems.forEach((item, index) => {
							this.menuItems[index].menu = 'sub'
						})

						// Wait 50ms for all menu items to be re-rendered
						setTimeout(() => {
							const iMenuBtnWidth = this.$refs.menuBtn?.offsetWidth || 46
							const iAvailableWidth = this.$refs.container?.offsetWidth
							let iAccumulatedWidth = 0

							// Loop through menu items and add them as long as there is space
							for (let i = 0; i < aWidths.length; i++) {
								// Unless this is the last menu item we need to make sure the menu button also has room
								const iCurrentlyAvailableWidth =
									!this.showExtraMenuItems && i === aWidths.length - 1
										? iAvailableWidth
										: iAvailableWidth - iMenuBtnWidth

								if (
									iAccumulatedWidth + aWidths[i].width <=
									iCurrentlyAvailableWidth
								) {
									iAccumulatedWidth += aWidths[i].width
									this.menuItems[i].menu = 'main'
								} else {
									break
								}
							}

							this.isRecalculating = false
						}, 50)

						this.lastCalculatedWidth = windowWidth
					}, 50)
				}
		}, 500),
		logout() {
			this.showSubmenu = false
			this.userStore.trigLogout()
		},
		subMenuTab(index, force = false, event) {
			// If item is last item
			// Move focus to menu btn
			if (
				force ||
				(index + 1 === this.subMenuItems.length &&
					this.$refs.hasOwnProperty('menuBtn'))
			) {
				event.preventDefault()
				this.$refs.menuBtn.focus()
			}
		},
	},
	mounted() {
		this.getAvailableWidth()

		window.addEventListener('resize', this.getAvailableWidth)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.getAvailableWidth)
	},
}
</script>
<style lang="scss">
.list-enter-active,
.list-leave-active {
	transition: all 1s;
}
.list-enter,
.list-leave-to {
	opacity: 0;
	transform: translateY(30px);
}

.menu__item__btn svg {
	path {
		transition: all 0.3s ease-in-out;
	}

	.close {
		opacity: 0;
	}
}

.menu__item__btn--expanded svg {
	.line1 {
		transform: translateX(4px) rotate(45deg);
		opacity: 0;
	}

	.line2 {
		transform: translateX(-18px);
		opacity: 0;
	}

	.line3 {
		transform: translateX(-7px) translateY(4px) rotate(-45deg);
		opacity: 0;
	}

	.close {
		opacity: 1;
	}
}
</style>
