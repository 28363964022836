<template>
	<div class="top-menu">
		<div class="constrained">
			<div class="top-menu__container">
				<div :key="key" class="flex items-center">
					<a class="top-menu__logo" href="https://www.glommen-mjosen.no/">
						<img
							alt="Glommen Mjøsen Skog"
							class="hidden md:block top-menu__logo__img h-4"
							height="16"
							src="/img/logo.png"
							srcset="/img/logo.png, /img/logo@2x.png 2x"
							width="118"
						/>
						<img
							alt="Glommen Mjøsen Skog"
							class="block md:hidden w-4 h-4"
							height="16"
							src="/img/glommen-mjosen-logo-circle.png"
							srcset="
								/img/glommen-mjosen-logo-circle.png,
								/img/glommen-mjosen-logo-circle@2x.png 2x
							"
							width="16"
						/>
					</a>
					<svg-icon class="mx-2 fill-current text-grey" height="8" name="chevron-right" width="5"/>
					<nuxt-link
						class="transition flex -mx-2 px-2 py-2 hover:bg-cyan-lighter text-black text-sm no-underline"
						exact-active-class="px-3 bg-cyan-lighter"
						to="/"
					>
						<svg-icon
class="fill-current text-grey"
											height="12"
											name="grid"
											style="margin: 2px" width="12"/>
						<!-- {{ $t('PORTAL') }} -->
					</nuxt-link>
					<template
						v-if="
							isLoggedIn && $route.path.length > 1 && activeAccount
						"
					>
						<svg-icon class="mx-2 fill-current text-grey" height="8" name="chevron-right" width="5"/>
						<account-switcher-button
							button-class="px-2 py-1 hover:bg-cyan-lighter"
							class="transition block border-none -mx-2 text-black text-sm no-underline"
						>
							<template v-if="key === 'my-account'">
								{{ $t('MY_ACCOUNT') }}
							</template>
							<template v-else>
								{{ activeAccountData?.Name || $t('LOADING') }}
							</template>
						</account-switcher-button>
					</template>
				</div>
				<ul class="top-menu__items">
					<li v-if="isLoggedIn" class="top-menu__items__item">
						<span v-if="showTopMenuItems && name">
							{{ $t('LOGGED_IN_AS_NAME', {name: name}) }}
						</span>
					</li>
					<li v-if="isLoggedIn && showTopMenuItems">
						<nuxt-link
							class="transition flex items-center px-2 py-1 text-black no-underline hover:bg-cyan-lighter focus:bg-cyan-lighter"
							to="/my-account"
							active-class="bg-cyan-lighter"
							exact-active-class=""
						>
							<svg-icon class="mr-1 fill-current text-grey" height="16" name="settings" width="16"/>
							<span>
								{{ $t('MY_ACCOUNT') }}
							</span>
						</nuxt-link>
					</li>
					<li v-if="isLoggedIn && showTopMenuItems">
						<button
							class="transition flex items-center px-2 py-1 text-sm text-black no-underline hover:bg-cyan-lighter focus:bg-cyan-lighter"
							style="line-height: 1.5rem"
							@click="onLogoutClick"
						>
							<svg-icon class="fill-current text-grey mr-1" height="16" name="lock-closed" width="16"/>
							<span>
								{{ $t('LOG_OUT') }}
							</span>
						</button>
					</li>
					<li
						v-else-if="showTopMenuItems"
						class="top-menu__items__item top-menu__items__item--active"
					>
						<i class="fill-current text-grey mr-1 -mb-1">
							<svg-icon height="16" name="lock-open" width="16"/>
						</i>
						<span>
							{{ $t('LOG_IN') }}
						</span>
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>
<script>
import {useUserStore} from "~/stores/user";
import {useAccountStore} from "~/stores/account";
import {mapState} from "pinia";
import AccountSwitcherButton from "~/components/buttons/AccountSwitcherButton.vue";
import {useAccountService} from "~/services/account";

export default {
	components: {
		AccountSwitcherButton,
	},
	setup() {
    const accountStore = useAccountStore()
    const { activeAccountData } = useAccountService()
		const userStore = useUserStore()
		return {
      activeAccountData,
      accountStore,
			userStore,
		}
	},
	data() {
		return {
			windowWidth: window.innerWidth,
		}
	},
	computed: {
		...mapState(useAccountStore, ['activeAccount']),
		isLoggedIn() {
			return this.userStore.isLoggedIn
		},
		isLoggingIn() {
			return this.userStore.isLoggingIn
		},
		key() {
			return (
				this.$route.name && this.$route.name.split('-').slice(0, 2).join('-')
			)
		},
		name() {
			return this.userStore.name
		},
		showTopMenuItems() {
			return this.windowWidth > 649
		},
	},
	mounted() {
		window.addEventListener('resize', this.getWindowWidth)
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.getWindowWidth)
	},
	methods: {
		onLogoutClick() {
			this.userStore.trigLogout()
		},
		getWindowWidth() {
			this.windowWidth = window.innerWidth
		},
	},
}
</script>
