<script setup lang="ts">
import {computed} from "vue";
import {useUserStore} from "~/stores/user";
import Default from './Default.vue';
import ForestOwner from './ForestOwner.vue'
import Contractor from './Contractor.vue'
import PlantDelivery from './PlantDelivery.vue'
import Accountant from './Accountant.vue'

const route = useRoute()
const userStore = useUserStore()
const path = computed(() => {
  return route.path.substring(0, 3)
})
const bannerType = computed(() => {
  if (!userStore.isLoggedIn) {
    return 'default'
  }
  if (path.value === '/fo' && route.name !== 'fo-unsigned-documents') {
    return 'forest-owner'
  } else if (path.value === '/co') {
    return 'contractor'
  } else if (path.value === '/pl') {
    return 'plant-delivery'
  } else if (path.value === '/ac') {
    return 'accountant'
  } else {
    return 'default'
  }
})
</script>

<template>
  <ForestOwner v-if="bannerType === 'forest-owner'" v-bind="$props" />
  <Contractor v-else-if="bannerType === 'contractor'" v-bind="$props" />
  <PlantDelivery v-else-if="bannerType === 'plant-delivery'" v-bind="$props" />
  <Accountant v-else-if="bannerType === 'accountant'" v-bind="$props" />
  <Default v-else v-bind="$props" />
</template>
