<template>
	<header :class="{ 'banner--transparent': !isLoggedIn }" class="banner">
		<div class="constrained">
			<div class="flex">
				<account-logo/>
				<menu-contractor/>
			</div>
		</div>
	</header>
</template>
<script>
import {mapState} from "pinia";
import MenuContractor from '../MenuContractor.vue'
import AccountLogo from './partials/AccountLogo.vue'
import {useUserStore} from "~/stores/user";

export default {
	components: {
		AccountLogo,
		MenuContractor,
	},
	computed: {
		...mapState(useUserStore, ['isLoggedIn']),
	},
}
</script>
